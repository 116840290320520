// in app.js
import React, { Component } from "react";
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import { render } from 'react-dom';
import { Delete, jsonServerRestClient, Admin, Resource } from 'admin-on-rest';

import { PostList, PostEdit, PostCreate, PostIcon } from './faqs.component';
import { PageList, PageEdit, PageCreate, PageIcon } from './pages.component';
import { SettingList, SettingEdit, SettingCreate, SettingIcon } from './settings.component';
import { NewsletterList, NewsletterIcon } from './newsletters.component';
import authService from "../../services/auth.service";
import { authProvider } from "./authProvider";
import authClient from "../../authClient";
// import uploadProviders from './upload'

export default class Login extends Component {
    componentDidMount() {
        const currentUser = authService.getCurrentUser();
        console.log(currentUser);
        if (!currentUser) {
            this.props.history.push("/login");
            window.location.reload();
        }
        this.setState({ currentUser: currentUser, userReady: true })
    }
    render() {
        return (
            <Admin authClient={authClient} authProvider={authProvider} theme={getMuiTheme(darkBaseTheme)} title="Admin Panel - Investor Hub" restClient={jsonServerRestClient('https://investorhub-backend.itechnolabs.tech/api')}>
                <Resource options={{ label: "FAQ's" }} name="faqs" list={PostList} edit={PostEdit} create={PostCreate} icon={PostIcon} remove={Delete} />
                <Resource name="pages" list={PageList} edit={PageEdit} icon={PageIcon} />
                <Resource name="settings" list={SettingList} edit={SettingEdit} icon={SettingIcon} />
                <Resource name="newsletters" list={NewsletterList} icon={NewsletterIcon} />
            </Admin>
        )
    }
}