// in src/authClient.js
import { AUTH_LOGIN } from 'admin-on-rest';
import { AUTH_LOGOUT } from 'admin-on-rest';
import authService from './services/auth.service';
import Axios from 'axios';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        return Axios
            .post("https://investorhub-backend.itechnolabs.tech/api/auth/signin", {
                username,
                password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            });
    }



    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('user');
        authService.logout();
        return Promise.resolve();
    }

    return Promise.resolve();
}