import React from 'react';
import { Edit, Datagrid, ImageInput, FormTab, TabbedForm, List, ImageField, FileField, Create, SimpleForm, DateField, TextField, EditButton, DisabledInput, TextInput, LongTextInput, DateInput } from 'admin-on-rest';
import BookIcon from 'material-ui/svg-icons/action/settings';
import '../../Admin.css';
export const SettingIcon = BookIcon;

const SettingTitle = ({ record }) => {
    return <span>Name: {record ? `"${record.company_name}"` : ''}</span>;
};

export const SettingList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField label="#" source="_id" />
            <TextField source="company_name" />
            <CommentEditButton {...props} />
        </Datagrid>
    </List>
);

const CommentEditButton = ({ record }) => (
    <EditButton basePath="/settings" label="Edit" record={record} />
);

export const SettingEdit = (props) => (
    <Edit title={<SettingTitle />} {...props}>

        <TabbedForm>

            <FormTab label="Web Settings">
                <DisabledInput source="id" />
                <TextInput source="company_name" />
                <TextInput source="company_short_info" />
            </FormTab>

            <FormTab label="Social">
                <TextInput source="fb_link" />
                <TextInput source="twitter_link" />
                <TextInput source="telegram_link" />
                <TextInput source="youtube_link" />
            </FormTab>

            <FormTab label="SEO">
                <TextInput source="meta_title" />
                <TextInput source="meta_description" />
                <TextInput label="Meta Keywords(, separated)" source="meta_keywords" />
            </FormTab>

            <FormTab label="Contact details">
                <TextInput label="E-mail 1" source="mail1" />
                <TextInput label="E-mail 2" source="mail2" />
                <TextInput label="Phone 1" source="phone1" />
                <TextInput label="Phone 2" source="phone2" />
                <TextInput label="Location (Address)" source="location" />
            </FormTab>


        </TabbedForm>
    </Edit >
);

export const SettingCreate = (props) => (
    <Create title="Create a Faq" {...props}>
        <SimpleForm>
            <TextInput source="company_name" />
            <TextInput source="company_short_info" />
            <ImageInput source="header_logo" label="Header Logo" accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput source="footer_logo" label="Footer Logo" accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>

            <TextInput source="fb_link" />
            <TextInput source="twitter_link" />
            <TextInput source="telegram_link" />
            <TextInput source="youtube_link" />

            <TextInput source="meta_title" />
            <TextInput source="meta_description" />
            <TextInput label="Meta Keywords(, separated)" source="meta_keywords" />

        </SimpleForm>
    </Create>
);














