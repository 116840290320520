import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Faqs = (props) => {
    const [faqDetails, setFaqDetails] = useState([])

    // const [response, setResponse] = useState(""); //TODO: socket response - 
    const API_URL = 'https://investorhub-backend.itechnolabs.tech/api/';
    useEffect(() => {
        const loadUsers = async () => {
            let faqsArr = []
            const faqData = await axios.get(API_URL + 'faqs');
            faqsArr = faqData.data;
            console.log(faqsArr);
            setFaqDetails(faqsArr)
        }
        loadUsers()



    }, [])
    return (faqDetails.length > 0) ? <>
        <section className="market_outer graph_outer contact_section">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12">
                        <div className="main_title aos-item aos-init" data-aos="fade-up">Frequently Asked Questions</div>
                        <p className="p_subtext text-center mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus at lacus rutrum, varius risus quis, fringilla odio. Praesent vel neque id orci accumsan pellentesque id vitae sem.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="accordian_custom">

                            <div className="accordion">
                                {
                                    faqDetails.map((row, i) => {
                                        if (i < 5) {
                                            return (
                                                <div className="card">
                                                    <div className="card-header" id={'faqhead1' + row.id}>
                                                        <a href="" className={(i == 0) ? 'btn btn-header-link' : 'btn btn-header-link collapsed'} data-bs-toggle="collapse" data-bs-target={'#faq' + row.id}
                                                            aria-expanded="true" aria-controls={'faq' + row.id}>{row.title}</a>
                                                    </div>

                                                    <div id={'faq' + row.id} className={(i == 0) ? 'collapse show' : 'collapse'} aria-labelledby={'faqhead1' + row.id} data-parent="#faq">
                                                        <div className="card-body">
                                                            {row.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                    )
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </> : <div className="spinner m-auto">
        {/* <img src="img/loading-buffering.gif" alt="loading-buffering" /> */}
    </div>;
}

export default Faqs;

