import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }
  componentDidMount() {

    const currentUser = AuthService.getCurrentUser();
    console.log(currentUser);
    if (currentUser) {
      this.props.history.push("/admin#/faqs");
      window.location.reload();
    }
    this.setState({ currentUser: currentUser, userReady: true })
  }
  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          this.props.history.push("/admin");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <section className="market_outer graph_outer contact_section">
        <div className="container">
          <div className="row">

            <div class="col-md-12">
              <div class="contact_inner market_div aos-item aos-init aos-animate" data-aos="zoom-in">
                <div class="main_title">Login</div>
                <Form

                  onSubmit={this.handleLogin}
                  ref={c => {
                    this.form = c;
                  }}
                >
                  <div class="row">
                    <div class="col-md-6">
                      <Input
                        type="text"
                        class="custom_input" placeholder="Enter User Name"

                        name="username"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        validations={[required]}
                      />


                    </div>
                    <div class="col-md-6">
                      <Input
                        type="password"
                        class="custom_input" placeholder="Enter Password"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        validations={[required]}
                      />


                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        class="contact_A suscribe_btn send"
                        disabled={this.state.loading}
                      >
                        {this.state.loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Login</span>
                      </button>

                    </div>
                  </div>
                  {this.state.message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {this.state.message}
                      </div>
                    </div>
                  )}
                  <CheckButton
                    style={{ display: "none" }}
                    ref={c => {
                      this.checkBtn = c;
                    }}
                  />
                </Form>
              </div>
            </div>


          </div>
        </div>
      </section>
    );
  }
}
