import React from "react";
import ReactDOM from "react-dom";
import { useLocation, BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import AdminHeader from './components/admin-header.component'; //Include Heder
import Header from './components/header'; //Include Heder
import Footer from './components/footer'; //Include Footer
import AdminFooter from './components/admin-footer.component'; //Include Footer
function Headers(props) {
  const location = useLocation();
  const pathLocation = location.pathname;
  const isAdmin = pathLocation.includes('admin');
  if (pathLocation.includes('admin') === false) {
    return <Header />;
  } else {
    return <AdminHeader />;
  }
}

function Footers(props) {
  const location = useLocation();
  const pathLocation = location.pathname;
  const isAdmin = pathLocation.includes('admin');
  if (pathLocation.includes('admin') === false) {
    return <Footer />;
  } else {
    return <AdminFooter />;
  }
}
ReactDOM.render(

  <BrowserRouter>
    <Headers isAdmin={false} />
    <App />
    <Footers isAdmin={false} />
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
