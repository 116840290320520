import { useState } from "react";
import React, { useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";

import 'react-toastify/dist/ReactToastify.css';
const Footer = (props) => {
  const [settingsData, setFaqDetails] = useState([])
  const [inputs, setInputs] = useState({});
  const notify = () => toast("Wow so easy !");
  // const API_URL = 'http://localhost:3090/api/';
  const API_URL = 'https://investorhub-backend.itechnolabs.tech/api/';

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    document.getElementById("submit").disabled = true;
    await axios.post(API_URL + 'newsletter', inputs)
      .then(response => toast.success(response.data.message))
      .catch(error => toast.error(error));
    document.getElementById("submit").disabled = false;
    setInputs(values => ({}))
  }


  useEffect(() => {
    const loadUsers = async () => {
      const API_URL = 'https://investorhub-backend.itechnolabs.tech/api/';
      var faqsArr = []


      const faqData = await axios.get(API_URL + 'settings/624e8e31ba5b6410edcda19d');
      faqsArr = faqData.data;
      console.log(faqsArr);
      setFaqDetails(faqsArr)
    }
    loadUsers()



  }, [])
  return (settingsData) ? <>
    <ToastContainer />
    <Helmet>
      <title>{settingsData.meta_title}</title>
      <meta name="keywords" content={settingsData.meta_keywords} />
      <meta
        name="description"
        content={settingsData.meta_description}
      />
    </Helmet>

    <footer className="footer_custom">
      <section className="section_space footer_outer">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-5">
              <div className="footer_inner">
                <Link to="#"><img src="img/logo_white.png" title={settingsData.company_name} alt="logo_white" /></Link>
                <p className="p_subtext">{settingsData.company_short_info}</p>
                <div className="social_mediatitle">Social Media</div>
                <ul className="footer_social_ul">
                  <li><a target="_blank" href={settingsData.fb_link}><i className="fab fa-facebook-f"></i></a></li>
                  <li><a target="_blank" href={settingsData.twitter_link}><i className="fab fa-twitter"></i></a></li>
                  <li><a target="_blank" href={settingsData.telegram_link}><i className="fas fa-paper-plane"></i></a></li>
                  <li><a target="_blank" href={settingsData.youtube_link}><i className="fab fa-youtube"></i></a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              <hr className="footer_hr_col" />
            </div>
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-12">
                  <div className="social_mediatitle">Newsletter</div>
                  <form onSubmit={handleSubmit}>
                    <div className="newsletter_div">
                      <input required type="email" placeholder="Enter email address.." name="email" value={inputs.email || ""}
                        onChange={handleChange} />
                      <input type="submit" className="contact_A suscribe_btn" id="submit" value="Send" />
                    </div>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <hr className="footer_hr" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="footer_inner">
                    <div className="social_mediatitle">Company</div>
                    <ul className="link_ul">
                      <li><Link to="/about">About</Link></li>
                      <li><Link to="/stocks">Stocks</Link></li>
                      <li><Link to="/faqs">FAQ's</Link></li>
                      <li><Link to="/contact">Contact Us</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer_inner">
                    <div className="social_mediatitle">Quick Link</div>
                    <ul className="link_ul">
                      <li><Link to="/how-it-works">How it works</Link></li>
                      <li><Link to="/user-guide">User guide</Link></li>
                      <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                      <li><Link to="/terms-of-service">Terms of Service</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="coprright_outer">
        <div className="row">
          <div className="col-md-12">
            <div className="footer_copyright_text">
              © 2022 Investor Hub. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  </> : <div className="spinner m-auto">
    {/* <img src="img/loading-buffering.gif" alt="loading-buffering" /> */}
  </div>
}
export default Footer;