import React, { useState, useEffect } from 'react';
import axios from 'axios';

const About = (props) => {
    const [pageDetails, setPageDetails] = useState([])

    // const [response, setResponse] = useState(""); //TODO: socket response - 
    const API_URL = 'https://investorhub-backend.itechnolabs.tech/api/';
    useEffect(() => {
        const loadUsers = async () => {
            let faqsArr = []
            const faqData = await axios.get(API_URL + 'pages/6257d48a21721c7860be272f');
            faqsArr = faqData.data;
            console.log(faqsArr);
            setPageDetails(faqsArr)
        }
        loadUsers()
    }, [])

    function createMarkup(parm) {
        return { __html: parm };
    }
    return (pageDetails) ? <>
        <section className="market_outer">
            <span className="shape4 evoke_shape4"></span>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="privacy_inner" dangerouslySetInnerHTML={createMarkup(pageDetails.description)}>

                        </div>
                    </div>
                </div>
            </div>
        </section>   </> : <div className="spinner m-auto">
        {/* <img src="img/loading-buffering.gif" alt="loading-buffering" /> */}
    </div>;
}


export default About;

