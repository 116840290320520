import { useState, useEffect } from "react";
import React from 'react';
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
const Contact = (props) => {
    const notify = () => toast("Wow so easy !");
    const API_URL = 'https://investorhub-backend.itechnolabs.tech/api/';
    // const API_URL = 'https://investorhub-backend.itechnolabs.tech/api/';
    const [inputs, setInputs] = useState({});
    const [settingsData, setFaqDetails] = useState([])

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        document.getElementById("submit").disabled = true;
        await Axios.post(API_URL + 'contact', inputs)
            .then(response => toast.success(response.data.message))
            .catch(error => toast.error(error));
        document.getElementById("submit").disabled = false;
        setInputs(values => ({}))
    }

    useEffect(() => {
        const loadUsers = async () => {
            const API_URL = 'https://investorhub-backend.itechnolabs.tech/api/';
            var faqsArr = []


            const faqData = await Axios.get(API_URL + 'settings/624e8e31ba5b6410edcda19d');
            faqsArr = faqData.data;
            console.log(faqsArr);
            setFaqDetails(faqsArr)
        }
        loadUsers()



    }, [])

    return (settingsData) ? <>

        <ToastContainer />
        <section className="market_outer">
            <span className="shape2"></span>
            <span className="shape3"></span>
            <span className="shape4"></span>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12">
                        <div className="main_title aos-item aos-init" data-aos="fade-up">Get in Touch</div>
                        <p className="p_subtext text-center mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus at lacus rutrum, varius risus quis, fringilla odio. Praesent vel neque id orci accumsan pellentesque id vitae sem.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-md-1 col-sm-12 col-ten">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="market_div aos-item aos-init" data-aos="zoom-in">
                                    <img src="img/icon5.png" alt="icon5" />
                                    <div className="market_title c_title">Phone</div>
                                    <p>{settingsData.phone1}</p>
                                    <p>{settingsData.phone2}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="market_div aos-item aos-init" data-aos="zoom-in">
                                    <img src="img/icon6.png" alt="icon6" />
                                    <div className="market_title c_title">Location</div>
                                    <p>{settingsData.location}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="market_div aos-item aos-init" data-aos="zoom-in">
                                    <img src="img/icon7.png" alt="icon7" />
                                    <div className="market_title c_title">Email</div>
                                    <p>{settingsData.mail1}</p>
                                    <p>{settingsData.mail2}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="market_outer graph_outer contact_section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="contact_inner market_div aos-item aos-init" data-aos="zoom-in">
                            <div className="main_title">Feel Free to Drop Us a Message</div>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input required type="text" className="custom_input" placeholder="Enter Your Name" name="name" value={inputs.name || ""}
                                            onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <input required type="email" className="custom_input" placeholder="Enter Email Address" name="email" value={inputs.email || ""}
                                            onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <textarea required className="custom_input" placeholder="Drop Message Here.." name='message' value={inputs.message || ""}
                                            onChange={handleChange}></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <input type="submit" className="contact_A suscribe_btn send" id="submit" value="Send" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </> : <div className="spinner m-auto">
        {/* <img src="img/loading-buffering.gif" alt="loading-buffering" /> */}
    </div>

}
export default Contact;