import React from 'react';
import { Edit, Datagrid, ImageInput, FormTab, TabbedForm, List, ImageField, FileField, Create, SimpleForm, DateField, TextField, EditButton, DisabledInput, TextInput, LongTextInput, DateInput } from 'admin-on-rest';
import BookIcon from 'material-ui/svg-icons/action/favorite';
export const NewsletterIcon = BookIcon;


export const NewsletterList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField label="#" source="_id" />
            <TextField source="email" />

        </Datagrid>
    </List>
);




















