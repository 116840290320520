import React from 'react';
import { DeleteButton, List, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton, DisabledInput, TextInput, LongTextInput, DateInput } from 'admin-on-rest';
import BookIcon from 'material-ui/svg-icons/action/done';
export const PostIcon = BookIcon;

export const PostList = (props) => (
    <List title="FAQ's list" {...props}>
        <Datagrid>
            <TextField label="#" source="_id" />
            <TextField source="title" />
            <CommentEditButton {...props} />
            <DeleteButtonx {...props} />
        </Datagrid>
    </List>
);

const CommentEditButton = ({ record }) => (
    <EditButton basePath="/faqs" label="Edit" record={record} />
);

const DeleteButtonx = ({ record }) => (
    <DeleteButton basePath="/faqs" lable="delete" record={record} />
);
const PostTitle = ({ record }) => {
    return <span>FAQ {record ? `"${record.title}"` : ''}</span>;
};

export const PostEdit = (props) => (
    <Edit title={<PostTitle />} {...props}>
        <SimpleForm>
            <DisabledInput source="id" />
            <TextInput source="title" />
            <LongTextInput source="description" />
        </SimpleForm>
    </Edit>
);


export const PostCreate = (props) => (
    <Create title="Create a Faq" {...props}>
        <SimpleForm redirect="/faqs">
            <TextInput source="title" />
            <LongTextInput source="description" />
        </SimpleForm>
    </Create>
);