import React from 'react';
import { DeleteButton, List, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton, DisabledInput, TextInput, LongTextInput, DateInput } from 'admin-on-rest';
import BookIcon from 'material-ui/svg-icons/action/description';
import RichTextInput from 'aor-rich-text-input';
export const PageIcon = BookIcon;

export const PageList = (props) => (
    <List title="Page list" {...props}>
        <Datagrid>
            <TextField label="#" source="_id" />
            <TextField source="title" />
            <CommentEditButton {...props} />
            {/* <DeleteButtonx {...props} /> */}
        </Datagrid>
    </List>
);

const CommentEditButton = ({ record }) => (
    <EditButton basePath="/pages" label="Edit" record={record} />
);

const DeleteButtonx = ({ record }) => (
    <DeleteButton basePath="/pages" lable="delete" record={record} />
);
const PageTitle = ({ record }) => {
    return <span>Page {record ? `"${record.title}"` : ''}</span>;
};

export const PageEdit = (props) => (
    <Edit title={<PageTitle />} {...props}>
        <SimpleForm>
            <DisabledInput source="id" />
            <DisabledInput source="title" />
            <RichTextInput source="description" />
        </SimpleForm>
    </Edit>
);

export const PageCreate = (props) => (
    <Create title="Create a Page" {...props}>
        <SimpleForm>
            <TextInput source="title" />
            <RichTextInput source="description" />
        </SimpleForm>
    </Create>
);