import React, { Component } from "react";

import UserService from "../services/user.service";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    return (
      <section className="market_outer">
        <img className="shape1" src="img/shape1.png" alt="shape1" />
        <span className="shape2"></span>
        <span className="shape3"></span>
        <span className="shape4"></span>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main_title aos-item" data-aos="fade-up">All The Market<br />
                Information You Need</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="market_div aos-item" data-aos="zoom-in">
                <img src="img/icon1.png" alt="icon1" />
                <div className="market_title">Company
                  Information</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="market_div aos-item" data-aos="zoom-in">
                <img src="img/icon2.png" alt="icon2" />
                <div className="market_title">Charting</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="market_div aos-item" data-aos="zoom-in">
                <img src="img/icon3.png" alt="icon3" />
                <div className="market_title">Financial Data</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="market_div aos-item" data-aos="zoom-in">
                <img src="img/icon4.png" alt="icon4" />
                <div className="market_title">Company
                  Valuation Tool</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
