import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom'
import axios from 'axios';

const Header = (props) => {



    function togglerOnMob() {
        document.body.classList.toggle('opennav')
    }


    // const API_URL = 'http://localhost:8080/api/';
    const API_URL = 'https://investorhub-backend.itechnolabs.tech/api/';

    const location = useLocation();
    const [text, setText] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const history = useHistory();

    /**
     * search ticker with code name and with actual company name.
     * @param {*} text 
     * @author - rohit
     */
    const onChangeHandler = async (text) => {
        setText(text)
        let matches = []
        if (text.length > 0) {
            //call api - company-info
            const response = await axios.get(API_URL + `company-info?exchange=${text}&limit=5`);
            console.log(response.data)
            matches = response.data;
        }
        console.log(matches.length);
        //set matching suggestions to suggestions list
        setSuggestions(matches)
    }

    /**
     * select ticker
     * send ticker name to company info page for detailed analyses
     * @param {*} text 
     * @author - rohit
     */
    const onSuggesthandler = (text) => {
        setText('');
        setSuggestions([])
        let path = `company-info?code=${text}`;
        history.push(path);
    }

    const pathLocation = location.pathname;
    const resultHeader = (location.pathname === '/company-info') ? 'Company Information' : (location.pathname === '/contact') ? 'Contact Us' : (location.pathname === '/faqs') ? `FAQ's` : (location.pathname === '/privacy-policy') ? `Privacy Policy` : (location.pathname === '/terms-of-service') ? `Terms of services` : (location.pathname === '/about') ? `About us` : (location.pathname === '/stocks') ? `Stocks` : (location.pathname === '/how-it-works') ? `How it works` : (location.pathname === '/user-guide') ? 'User Guide' : (location.pathname === '/login') ? 'Login' : '';
    return <div>
        <main className={(location.pathname !== '/') ? 'main_div company_bg' : 'main_div'}>

            <header>
                <div className="headerCol">
                    <div className="container custom-container">
                        <div className="row align-items-center">
                            <div className="col-md-3 header_col3">
                                <div className="col-auto">
                                    <div className="logo">
                                        <Link to="/"><img src="img/logo.png" alt="" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 header_col9">
                                <div onClick={togglerOnMob} id='togglerr' className="toggle d-lg-none d-md-block">
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                    <div className="bar"></div>
                                </div>
                                <div className="col">
                                    <div className="navCol">
                                        <div className="nav_menu">
                                            <div className="row mobile_row">
                                                <div className="col-md-12">
                                                    <div className="logo">
                                                        <Link to="#">
                                                            <img src="img/logo.png" alt="" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <ul>
                                                        <li>
                                                            <Link to="/">Home</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/about">About</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/stocks">Stocks</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/faqs">FAQ's</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-3">
                                                    <ul className="contact-ul">
                                                        <li>
                                                            <Link className="contact_A" to="/contact">Contact Us</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {
                (location.pathname === '/' || location.pathname === '/home') &&

                <section className="banner_outer">
                    <span className="shape2 bannershape2"></span>
                    <span className="shape4 bannershape4"></span>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2 col-sm-12">
                                <div className="investor_inner">
                                    <div className="built_investor_title aos-item" data-aos="fade-up">Built by investors, For investors</div>
                                    <div className="investor_maintitle aos-item" data-aos="fade-up">The Investor Hub</div>
                                    <p className="p-text aos-item" data-aos="fade-up">I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer.</p>
                                    <div className='serach-tickers'>
                                        <input className="search_input aos-item" data-aos="fade-up" type="text" placeholder="Search..."
                                            onChange={e => onChangeHandler(e.target.value)}
                                            name=""
                                            value={text}
                                            onBlur={() => {
                                                setTimeout(() => {
                                                    setSuggestions([])
                                                }, 100)
                                            }}
                                        />
                                        <div className='suggested-tickers' style={{ display: (suggestions.length > 0) ? 'block' : 'none' }}>
                                            {suggestions && suggestions.map((suggestion, i) =>
                                                <p onClick={() => onSuggesthandler(suggestion.Code + '.' + suggestion.Exchange)}
                                                    key={i}>{suggestion.Name}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <img className="tab_img aos-item" data-aos="zoom-in" src="img/img.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </section>
            }
            {
                (location.pathname !== '/') &&
                <section className="banner_outer company_info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="investor_inner">
                                    <div className="investor_maintitle aos-item" data-aos="fade-up">{resultHeader}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

        </main>

    </div >;
}

export default Header;

